// Imports
var ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .wfm-select {
  font-family: "Open Sans", Arial, sans-serif;
  padding: 0px;
  height: inherit;
  font-size: 14px;
}
::ng-deep .wfm-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
  padding: 0px;
}
::ng-deep .wfm-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked .wfm-select-option {
  background: #D9EBF5;
}
::ng-deep .wfm-select .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background: #D9EBF5;
}
::ng-deep .wfm-select .wfm-select-option {
  height: 40px;
  padding-left: 10px;
  padding-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
::ng-deep .wfm-select .wfm-select-option.wfm-bg-white {
  background: white;
}
::ng-deep .wfm-select .wfm-select-option.wfm-bg-white-separator {
  border-top: 1px solid #d9d9d9;
  background-color: white;
}
::ng-deep .wfm-select .wfm-select-option.wfm-bg-gray {
  background-color: #F7F8FA;
}
::ng-deep .wfm-select .wfm-select-option.wfm-bg-gray-separator {
  border-top: 1px solid #d9d9d9;
  background-color: #F7F8FA;
}
::ng-deep .wfm-select input:disabled {
  color: rgb(112, 126, 141) !important;
}
::ng-deep .wfm-select .ng-select-container:hover {
  box-shadow: none;
}

.input-label {
  color: #8c95a5;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

::ng-deep .ng-select .ng-select-container {
  border-radius: 0px;
}

::ng-deep .ng-select .ng-arrow-wrapper .ng-arrow {
  display: none !important;
}

::ng-deep .ng-select span.ng-arrow-wrapper:before {
  content: "\\e918";
  color: #007CBE;
  display: inline-block;
  font-family: "icomoon";
  font-size: 12px;
  text-rendering: auto;
}

::ng-deep .ng-select.ng-select-opened.ng-select-container:hover {
  border-color: #237bc2;
}

::ng-deep .ng-select .ng-select-container:hover {
  border-color: #237bc2;
}

::ng-deep .ng-select.ng-select-disabled > .ng-select-container:hover {
  border: 1px solid #ccc;
}

::ng-deep .toolbar-select .input-label {
  margin-top: 2px;
}

::ng-deep .toolbar-select .ng-select.ng-select-disabled .ng-value {
  color: #9b9ea0;
}

::ng-deep .toolbar-select .ng-select.ng-select-single .ng-select-container {
  height: 40px;
}

::ng-deep .ng-select.ng-select-disabled .ng-arrow-wrapper::before {
  color: rgb(173, 173, 173) !important;
}`, ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
